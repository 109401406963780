import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import DataTable from './components/DataTable';
import CtrHeader from './components/ucHeader';
import CurrentRider from './components/ucCurrentRider';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const apiUrl = process.env.REACT_APP_API_URL;

//const socket = io(apiUrl + ':3001');

const App = () => {

//    axios.get('http://10.0.0.2/bmfwm/bmfxcrud/tabletest.php')

const [dataAA, setDataAA] = useState([]);
const [changedId, setChangedId] = useState(null);
const [currentRidersCollection,setcurrentRidersCollection] = useState([]);

/*
useEffect(() => {




  // Cargar los datos iniciales
    axios.get(apiUrl + '/bmfwm/bmfxcrud/getCurrentSetting.php')
    .then(response => {
      setcurrentRidersCollection(response.data);
      setChangedId(response.data.bib);
          axios.post(apiUrl + '/bmfwm/bmfxcrud/getRidersAtRacePhaseGroup.php', new URLSearchParams({
            race: response.data.parentrace, // Reemplaza 'valorNombre' con el valor que necesites
            phase: response.data.phase // Reemplaza 'valorId' con el valor que necesites
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          } )
          .then(response => {
            setDataAA(response.data);
          })
          .catch(error => {
            console.error('Error al obtener los datos:', error);
          });
    })
    .catch(error => {
      console.error('Error al obtener los datos:', error);
    });    

    socket.on('newDocument', async () => {
      try {
        const response = await fetch(apiUrl + '/bmfwm/bmfxcrud/getCurrentSetting.php');
        const data = await response.json();
        setcurrentRidersCollection(data);
        setChangedId(data.bib);
        axios.post(apiUrl + '/bmfwm/bmfxcrud/getRidersAtRacePhaseGroup.php', new URLSearchParams({
          race: data.parentrace, // Reemplaza 'valorNombre' con el valor que necesites
          phase: data.phase // Reemplaza 'valorId' con el valor que necesites
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        } )
        .then(response => {
          setDataAA(response.data);
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    });

    // Conectar al socket y escuchar las actualizaciones
    socket.on('connect', () => {
      console.log('Conectado al servidor de WebSocket');
    });

    socket.on('updateData', (newData) => {
      console.log('Datos recibidos del servidor:', newData);
      setDataAA(newData);
    });

    socket.on('documentChanged', (change) => {
      console.log('Documento cambiado:', change);
      //setChanges((prevChanges) => [...prevChanges, change]);
      if (change && change._id) {
        //setChangedId(change.documentKey._id);
        reloadData();
      } else {
        console.error('El cambio no contiene un campo _id válido:', change);
      }
    });

    socket.on('disconnect', () => {
      console.log('Desconectado del servidor de WebSocket');
    });

    // Limpiar la conexión cuando el componente se desmonta
    return () => {
      socket.off('updateData');
    };
}, []);

*/
  const reloadData = () => {
    // Aquí puedes hacer una solicitud al servidor para obtener los datos actualizados
    fetch(apiUrl +'/bmfwm/bmfxcrud/tabletest.php')
      .then(response => response.json())
      .then(newData => setDataAA(newData))
      .catch(error => console.error('Error al recargar los datos:', error));
  };

  return (
    <div className="App">
      <CtrHeader data={currentRidersCollection} />
      <div className="row d-none">
        <div className="col-md-3">
          <CurrentRider data={currentRidersCollection} />
        </div>
        <div className="col-md-9">
            <DataTable data={dataAA} changedId={changedId} />
        </div>
      </div>
    </div>
  );
} 

export default App;
