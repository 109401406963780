import React, { useEffect, useRef } from 'react';
import { useTable, useSortBy } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';

const apiUrl = process.env.REACT_APP_API_URL;

const DataTable = ({ data, changedId }) => {

    const columns = React.useMemo(
        () => [
            { Header: 'Rank', accessor: 'Rank' },
            { Header: 'Bib', accessor: 'Bib' },
            { Header: 'Name', accessor: 'Name' },
            { Header: 'Nat', accessor: info => <img src = { apiUrl + "/bmfwm/images/flags/" + info.NOC + ".BMP"} alt={info.NOC} ></img> },
            { Header: 'Heat', accessor: 'Heat' },
            { Header: 'Run1', accessor: 'Run1' },
            { Header: 'Run2', accessor: 'Run2' },
            { Header: 'Result', accessor: 'Result' },
            { Header: 'StartingPosition', accessor: 'StartingPosition' }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    const tableRef = useRef(null);

    useEffect(() => {
        if (changedId && tableRef.current) {
        const rowElement = tableRef.current.querySelector(`[data-id='${changedId}']`);
        if (rowElement) {
            rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            rowElement.focus();
        }
        }
    }, [changedId, data]);
    
    return (
    <div className="container mt-3 table-container" ref={tableRef}>
        <table id="table"
            data-toggle="table"
			data-height="450"
			data-fixed-scroll="true"
            {...getTableProps()}
            className="table table-bordered table-striped table-hover">
            <thead className="table-dark ">
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                        {column.isSorted
                            ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                            : ''}
                        </span>
                    </th>
                    ))}
                </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                <tr {...row.getRowProps()} 
                data-id={row.original.Bib} tabIndex={-1}
                className={(row.original._id.$oid === changedId || row.original.RiderId === changedId || row.original.Bib === changedId) ? 'table-warning' : ''}>
                    {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                </tr>
                );
            })}
            </tbody>
        </table>
    </div>
    );
};

export default DataTable;
