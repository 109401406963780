import React from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

const CurrentRider = ({ data }) => {

    return(
        <div className="user-panel container mt-3">
        <input type="hidden" name="raceid" className="form-control" id="raceid" size="40" value={data.parentrace}  />
        <input type="hidden" name="racename" className="form-control" id="racename" size="40" value={data.racename}  />
        <input type="hidden" name="phase" className="form-control" id="phase"  value={data.phase}  />
        <input type="hidden" name="heat" className="form-control" id="heat" value={data.heat}  />
        <input type="hidden" name="currentrun" id="currentrun" placeholder="Run" value={data.run} />  
        <div className="row">
            <div className="col">
                <div className="input-group mb-3">
                    <span className="input-group-text" title="Bib">
                    <span>BIB</span>
                    </span>
                    <input type="text" name="riderbib" id="riderbib" className="form-control" placeholder="Bib" value={data.bib} DISABLED/>  
                </div>
            </div>
            <div className="col">
                <img id="imgnat" src={apiUrl + "/bmfwm/images/flags/"+ data.noc +".BMP"} />
                <input type="hidden" name="ridernoc" id="ridernoc" className="form-control" placeholder="NOC" value={data.noc} />  
            </div>
        </div>

        <div className="input-group mb-3">
            <span className="input-group-text" title="Rider">
            <span>Rider</span>
            </span>
            <input type="text" name="ridername" id="ridername" className="form-control" placeholder="Rider" value={data.rider} DISABLED/>  
        </div>
    </div>
    );
};

export default CurrentRider;